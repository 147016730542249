import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import store from './store'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactGA from 'react-ga'

// Initialize Google Analytics
ReactGA.initialize("G-5SYWBFWQ60")

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
)
