import React from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import YouTubeIcon from '@mui/icons-material/YouTube'
import Grid from '@mui/material/Grid'

import { FooterStyle } from '../styles'
import {LinkedIn} from "@mui/icons-material";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const Footer = () => {
    return (
        <Box sx={FooterStyle.container}>
            <Container {...FooterStyle.margins}>
                <Box sx={FooterStyle.flex}>
                    <Box>
                        <Typography sx={FooterStyle.para.style} {...FooterStyle.para.props} gutterBottom>
                            Temerty Faculty of Medicine <br/>1 King's College Circle <br/>Medical Sciences Building<br/>Room 2111<br/>Toronto, ON. M5S 1A8
                        </Typography>
                        <Typography {...FooterStyle.para.props} gutterBottom>
                            &copy; 2022 University of Toronto
                        </Typography>
                    </Box>
                    <Box sx={FooterStyle.linkContainer}>
                        <Grid container spacing={1}>
                            <Grid item sx={FooterStyle.hideLink}>
                                <Link href='mailto:tcairem.comms@utoronto.ca' sx={FooterStyle.links.style}>
                                    <EmailIcon />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='https://www.linkedin.com/company/tcairem' {...FooterStyle.links.props} sx={FooterStyle.links.style}>
                                    <LinkedInIcon />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='https://twitter.com/UofT_TCAIREM' {...FooterStyle.links.props} sx={FooterStyle.links.style}>
                                    <TwitterIcon />
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href='https://www.instagram.com/uoft_tcairem/' {...FooterStyle.links.props} sx={FooterStyle.links.style}>
                                    <InstagramIcon />
                                </Link>
                            </Grid>
                            <Grid item sx={FooterStyle.hideLink}>
                                <Link href='https://www.youtube.com/user/UofTMed' {...FooterStyle.links.props} sx={FooterStyle.links.style}>
                                    <YouTubeIcon />
                                </Link>
                            </Grid>
                        </Grid>
                        <br/>
                        <Typography {...FooterStyle.temertyContainer} gutterBottom>
                            <span style={FooterStyle.temertyColor}>Temerty</span><span style={FooterStyle.medicineColor}>Medicine</span>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default Footer